console.log("ravenchat.js loading...");

import jQuery from "jquery";
import { Auth, Storage } from 'aws-amplify';
import { setUserId, setCognitoIdentityId, showNotification } from './upload.js';


const ravenchattextarea = document.getElementById('ravenchattextarea');
const generateRavenFetch = document.getElementById('generateRavenFetch');

// let userId;  // Declare userId here?
// // let userId = await setUserId();
// // console.log("Result:");
// // console.log(userId);
// // let userId ;
// // setUserId();
// // console.log("Result:")
// // console.log(userId)

// let cognitoIdentityId;
// // setCognitoIdentityId();

// // You can use an IIFE (Immediately Invoked Function Expression) to use async/await at the top level if needed
// (async () => {
//   await setUserId(); // This will wait for the userId to be set
//   console.log("Result:");
//   console.log(userId);

//   await setCognitoIdentityId(); // Wait for cognitoIdentityId to be set
//   // Now you can proceed with using userId and cognitoIdentityId as they have been set
// })();

let userId;
let cognitoIdentityId;

async function initializeUserIdAndCognitoId() {
    userId = await setUserId(); // This function should set userId globally
    cognitoIdentityId = await setCognitoIdentityId(); // This function should set cognitoIdentityId globally
    console.log("Result:");
    console.log(userId); // Now userId should be defined
    console.log(cognitoIdentityId); // Now cognitoIdentityId should be defined
}

initializeUserIdAndCognitoId(); // Call the async function to initialize IDs


generateRavenFetch.addEventListener('click', async () => {
  // set the text variable to the value of the textarea input
  // construct the data payload for the websocket call
  // make a function call to send the websocket data payload event
  // trigger a spinner
  document.getElementById('ravenChatResponse').innerHTML = '';
  const ravenQuestionText = ravenchattextarea.value;
  const ravenQuestionFormattedText = ravenQuestionText.replace(/(\r\n|\n|\r)/gm, ' ');
  
  const data = {
    action: 'ravenChat',
    ravenQuestionText: ravenQuestionFormattedText,
    user_id: userId,
    identity_id: cognitoIdentityId
  };
  console.log("Sending message to websocket for ravenChat action - data:");
  console.log(data);
  // ravenSocket.send(JSON.stringify(data));
  sendMessage(data);
  ravenchatspinner.style.display = "block";
  
});

function sendMessage(data) {
  // Check if the WebSocket's readyState is OPEN
  if (ravenSocket.readyState === 1) {
    ravenSocket.send(JSON.stringify(data));
    console.log("Message sent to WebSocket.");
  } else {
    console.log("WebSocket is not open. Current state:", ravenSocket.readyState);
    // Optionally, try to reconnect or handle this case appropriately
    reconnectWebSocketAndSend(data);
  }
}

function reconnectWebSocketAndSend(data) {
  console.log("Attempting to reconnect WebSocket...");
  // Close the existing WebSocket if it's not already closed
  if (ravenSocket.readyState !== 0) {
    ravenSocket.close();
  }
  // Reinitialize the WebSocket
  ravenChatWebSocketUrl = "wss://xcjmve0aih.execute-api.us-west-2.amazonaws.com/production/";
  ravenSocket = new WebSocket(ravenChatWebSocketUrl);
  // const ravenSocket = new WebSocket(ravenChatWebSocketUrl);
  
  // Add an event listener to send the message once the WebSocket is open
  ravenSocket.addEventListener('open', function(event) {
    console.log('WebSocket reconnected successfully.');
    ravenSocket.send(JSON.stringify(data));
    console.log("Message sent to WebSocket after reconnecting.");
  });

  // You may want to add the same listeners (message, error, close) to the new WebSocket
}


// WebSocket handlers...

const ravenChatWebSocketUrl = "wss://xcjmve0aih.execute-api.us-west-2.amazonaws.com/production/";
const ravenSocket = new WebSocket(ravenChatWebSocketUrl);

ravenSocket.addEventListener('open', (event) => {
  if (userId) {  // Check if the user is logged in
    console.log('ravenSocket connected successfully.');
    console.log(event);
    // showNotification("Connected to RavenAI", 'success');
  } else {
    // console.log('WebSocket connection established, but no user is logged in.');
  }
});

// Custom function to scroll with padding
function scrollToBottomWithPadding(elementId, padding) {
  const element = document.getElementById(elementId);
  if (element) {
    // Calculate the bottom position with padding
    const bottomPosition = element.scrollHeight - element.clientHeight - padding;

    // Scroll to the bottom of the element with padding
    element.scrollTo({ bottom: bottomPosition + padding, behavior: 'smooth', block: 'end' });
  }
}

// Initialize a variable to accumulate message chunks.
let messageChunks = '';

ravenSocket.addEventListener('message', (event) => {
  console.log("ravenSocket Response Payload:");
  console.log(event.data);
  // showSpinners();
  const data = JSON.parse(event.data);

  //These are messages coming back from events publishing to the websocket connection
  //The messages have a data.type to branch the logic of handling the message in the UI/UX
  //The messages should alert or notify the user on the status and provide some information
  //The messages may come from a lambda event run by a state machine or as an api lambda proxy/etc
  //The message data.type is defined in the lambda functions triggering the events in most cases, with data payloads coming
  //other api's or dynamodb tables for example
  //The sending of data payloads to the websockets are handled by the click events/event listener
  switch(data.type) {
    case 'ravenResponseChunk':
      console.log("Raven Response Chunk");
      // Accumulate chunks of the message
      ravenchatspinner.style.display = "none";
      // setTimeout(() => {
      //   document.getElementById('ravenchatresponsebuffer').scrollIntoView({ behavior: 'smooth', block: 'end' });
      // });
      messageChunks += data.message;
      // Display the accumulating message chunks in real-time
      document.getElementById('ravenChatResponse').innerHTML = messageChunks.replace(/\n/g, '<br>');
      // Use the custom function to scroll, adjust padding as needed
      // scrollToBottomWithPadding('ravenChatResponse', 100); // Adjust the padding value as needed
      setTimeout(() => {
        document.getElementById('ravenchatresponsebuffer').scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" },0);
      });
      
      break;

    
    case 'streamComplete':
      console.log("Stream Complete");
      // Process the accumulated message chunks
      document.getElementById('ravenChatResponse').innerHTML = messageChunks.replace(/\n/g, '<br>');
      // Reset the accumulator for the next message
      messageChunks = '';
      
      break;

    case 'ravenResponse':
      console.log("Raven Response")
      ravenchatspinner.style.display = "none";
      document.getElementById('ravenChatResponse').innerHTML = data.message.replace(/\n/g, '<br>');
      // interviewPrepMessage.innerHTML = data.message.replace(/\n/g, '<br>'); // Replace newline characters with HTML line breaks

      break
    case 'statusUpdate':
      console.log("Connected to ravenChatGPT function");
      document.getElementById('ravenChatResponse').textContent = "Your question has been received, a response is on the way.";
      break;


    case 'textExtracted':
      // console.log("textExtract completed")
      // console.log(data.type)
      // candidateprofilespinner.style.display = "none";
      // candidateprofiletick.style.display = "block";
      
      document.getElementById('resume_text_header').style.display = 'block';
      document.getElementById('extractedText').textContent = data.resume_text;
      
      // spinner.style.display = 'flex';
      // // Move to the next form section
      // document.getElementById('descriptionForm').style.display = 'none';
      // Hide the current form (Step 1)
      // document.getElementById('descriptionForm').style.display = 'none';

      // Display the next form (Step 2)
      document.getElementById('saveForm').style.display = 'block';
      // const headerHeight = 70; // Header height set to 70 pixels

      // // Calculate the position to scroll to
      // const position = collapseThree.offsetTop - headerHeight;
      // setTimeout(() => {
      //   collapseThree.scrollIntoView({ 
      //     top: position,
      //     behavior: 'smooth' });
      // }, 50);
      // collapseThree.classList.toggle("show");
      // setActiveTab(3);
      
      
      break;

    case 'generationComplete':
      if (typeof data.message === 'string') {
          // const coverLetterTextarea = document.getElementById('finalCoverLetter');
          // coverLetterTextarea.setAttribute('rows', 10);
          // document.getElementById('finalCoverLetter').value = data.message;
          finalCoverLetter.setText(data.message);
          // finalCoverLetter.insertText(data.message);
          // hideSpinners()
          coverletterspinner.style.display = "none";
          coverlettertick.style.display = "block";
          // setTimeout(() => {
          //   collapseFour.scrollIntoView({ behavior: 'smooth' });
          // }, 50);
          // collapseFour.classList.toggle("show");
          
      } else {
          console.error("Cover letter text is undefined or not present in the response.");
      }
      
      break;
    
      case 'interviewPrepComplete':
        if (data.message) {
          const interviewPrepMessage = document.getElementById('interviewPrepMessage');
          interviewPrepMessage.innerHTML = data.message.replace(/\n/g, '<br>'); // Replace newline characters with HTML line breaks
          // hideSpinners();
          interviewprepspinner.style.display = "none";
          interviewpreptick.style.display = "block";
          
          // setTimeout(() => {
          //   collapseFive.scrollIntoView({ behavior: 'smooth' });
          // }, 50);
          // collapseFive.classList.toggle("show");
        } else {
          console.error("Job strategy text is undefined or not present in the response.");
        }
        break;
      
        case 'candProfileComplete':
          if (data.message) {
            const candidateProfileMessage = document.getElementById('candidateProfileMessage');
            candidateProfileMessage.innerHTML = data.message.replace(/\n/g, '<br>'); // Replace newline characters with HTML line breaks
            // hideSpinners();
            candidateprofilespinner.style.display = "none";
            candidateprofiletick.style.display = "block";
            // interviewprepspinner.style.display = "none";
            // interviewpreptick.style.display = "block";
            
            // setTimeout(() => {
            //   collapseFive.scrollIntoView({ behavior: 'smooth' });
            // }, 50);
            // collapseFive.classList.toggle("show");
          } else {
            console.error("Job strategy text is undefined or not present in the response.");
          }
          break;

    // Handle other message types if necessary
  }

  
});

ravenSocket.addEventListener('error', (event) => {
  console.log('WebSocket Error:', event);
  // showNotification("Websocket error: " + event, 'error');

  // showErrorAlert('An error occurred while communicating with the server.');
});

ravenSocket.addEventListener('close', (event) => {
  console.log('WebSocket is closed now.');
  // console.log(event)
  // showNotification("Websocket is closed", 'error');

  // showErrorAlert('The connection to the server was closed.');
});




console.log("ravenchat.js loaded...");
