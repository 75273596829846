

import { Auth } from 'aws-amplify';

function deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
}

// Sign Out function
export async function signOut(event) {
    if (event) {
        event.preventDefault();
    }
    console.log("signOut triggered...");

    // Wrap the setTimeout in a Promise
    await new Promise((resolve, reject) => {
        setTimeout(async () => {
            try {
                await Auth.signOut();
                localStorage.clear();
                sessionStorage.clear();
                deleteAllCookies();

                resolve(); // Resolve the promise after all operations are complete
            } catch (error) {
                console.log('Error signing out: ', error);
                alert('There was an error logging out. Error Message: ', error);
                reject(error); // Reject the promise if there is an error
            }
        }, 10); // slight delay
    });

    // Redirect after successful sign out and completion of setTimeout
    window.location.href = '/index.html';
    window.on('load', function() {
        preloader.fadeOut('slow', function() {
          $(this).remove();
        });
      });
};

// Event listener for the "Logout" button
document.addEventListener('DOMContentLoaded', () => {
    const btnLogout = document.getElementById('nav-logout');

    if (btnLogout) {
        btnLogout.addEventListener('click', signOut);
    }
});

// Event listener for the "Logout Alt" button
document.addEventListener('DOMContentLoaded', () => {
    const btnLogoutalt = document.getElementById('nav-logout-alt');

    if (btnLogoutalt) {
        btnLogoutalt.addEventListener('click', signOut);
    }
});
