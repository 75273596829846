// console.log("app.js started...");

// import jQuery from "jquery";
import $ from 'jquery';
import DataTable from 'datatables.net-bs5';
import { Amplify, Auth, Storage, API } from 'aws-amplify';
import awsconfig from './aws-exports';
import { userAuthState } from './auth_user';
import { checkAuthContent } from './auth_content';
import { signUp, confirmSignUp, resendConfirmationCode } from './auth_signup';
import { signIn } from './auth_login';
import { forgotPass, confirmForgotPass } from './auth_forgot_password';
import { signOut } from './auth_logout';
import Quill from 'quill';
import { uploadDocument,fetchUserId, showNotification } from './upload';
// import { exportPdf } from "./pdfexport";
import { getCachedAuthInfo, fetchDynamoDBItems, deleteCoverLetter, fetchUserPreferences, updateUserPreferences } from "./cover_letters";
import './verify.js';
import './ravenchat';


const coverletterModalEditor = new Quill('#coverletterModalEditor', {
  theme: 'snow'
});

var preloader = $('.js-preloader'); // Move this line outside the IIFE

(function(){
  var preload = $('.js-preload-me').length;

  if(preload){
    $(window).load(function () {
      preloader.fadeOut('slow',function () {
        $(this).remove();
      });
    });
  }
})();


// Initialize Amplify
Amplify.configure(awsconfig);

async function checkUser() {
  try {
    let user = await Auth.currentAuthenticatedUser();
    // console.log("User object and attributes:")
    // console.log(user);
    // console.log(user.attributes['custom:subscription_type']);
    // const auth_flow_type = user.authenticationFlowType;
    // document.getElementById("auth_flow_type_info").innerHTML = auth_flow_type;
    return user;
  } catch (error) {
    // console.log('User not logged in.');
    return null;
  }

};

// const attributes = checkUser();
// const attributes = await checkUser(); // Use await here


checkAuthContent();

// Function to hide the preloader
function hidePreloader() {
  preloader.fadeOut('slow', function() {
    $(this).remove();
  });
}

window.onload = async () => {
  const user = await checkUser();
  // console.log("User");
  // console.log(user);
  if (!user) {
    // console.log('User not logged in, not fetching items.');
    hidePreloader();
    return;
  }
  const attributes = user.attributes;
  console.log("Attrs");
  console.log(attributes);
  const useremail = attributes.email;
  // console.log(useremail);
  const emailverified = attributes.email_verified;
  const given_name = attributes.given_name;
  // console.log("Given Name");
  // console.log(given_name);
  const family_name = attributes.family_name;
  const subscription_type = attributes['custom:subscription_type'];
  const sub_id = attributes.sub;
  const auth_flow_type = user.authenticationFlowType;
  console.log("Attributes");
  console.log(attributes);
  


  const response = await fetchDynamoDBItems();
  console.log("Response from dynamoDB fetch");
  console.log(response);
  const responseBody = JSON.parse(response.body);
  const coverLetterData = responseBody.items;
  console.log(response);
  console.log('Parsed response body:', responseBody);
  console.log('Cover Letter Data:', coverLetterData);

  const userPreferences = await fetchUserPreferences();
  const userPreferencesBody = JSON.parse(userPreferences.body);
  const userPreferencesResponse = userPreferencesBody.userPrefs[0];
  const userPreferencesEmail = userPreferencesResponse.email;
  const first_name = userPreferencesResponse.first_name;
  const last_name = userPreferencesResponse.last_name;
  const userCurrentStatus = userPreferencesResponse.currentStatus;
  const stripeCustomerId = userPreferencesResponse.stripeCustomerId;
  const userCreatedAtiso = userPreferencesResponse.created_at;
  // Create a new Date object using the ISO string
  const date = new Date(userCreatedAtiso);

  // Format the date in a readable way
  const userCreatedAt = date.toLocaleDateString("en-US", {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  });

  console.log(userPreferences);
  console.log('Parsed User Preferences body:', userPreferencesBody);
  console.log('Parsed User Preferences response items:', userPreferencesResponse);
  console.log('Parsed User Preferences email:', userPreferencesEmail);
  // Formatting function for row details - modify as you need
  // Hide the preloader after all dynamic content has been loaded and rendered
  // preloader.fadeOut('slow', function () {
  //   $(this).remove();
  // });
  // Check if the userPreferences equals 'trial'
  if (userCurrentStatus === "trial") {
    // Get the element by its ID
    var subscription_activation = document.getElementById("subscription_activation");

    // Change the display style to 'block' or 'flex' or whatever suits your layout
    subscription_activation.style.display = "block"; // or "flex", "inline", etc., depending on your needs
  }
  document.getElementById("useremail").innerHTML = userPreferencesEmail;
  document.getElementById("user_given_name_welcome").innerHTML = first_name;
  document.getElementById("user_family_name").innerHTML = last_name;
  document.getElementById("user_given_name").innerHTML = first_name;
  document.getElementById("subscription_type_info").innerHTML = subscription_type;
  document.getElementById("useremail_info").innerHTML = userPreferencesEmail;
  document.getElementById("emailverified_info").innerHTML = emailverified;
  // document.getElementById("sub_id_info").innerHTML = sub_id;
  document.getElementById("auth_flow_type_info").innerHTML = auth_flow_type;
  document.getElementById("userCurrentStatus").innerHTML = userCurrentStatus;
  document.getElementById("userCreatedAt").innerHTML = userCreatedAt;
  document.getElementById("stripeCustomerId").innerHTML = stripeCustomerId;
  // Encoding the email address
  let encodedEmail = encodeURIComponent(userPreferencesEmail);
  document.getElementById("subscribeLink").href = userPreferencesResponse.payment_link_url + "?client_reference_id=" + sub_id + "&prefilled_email=" + encodedEmail;

  // Dynamically update the payment link with the user's sub_id
  // const paymentLinkBaseURL = "https://buy.stripe.com/test_28obLLbBr3UrfAIfYY?client_reference_id=";
  // document.getElementById("subscribeLink").href = paymentLinkBaseURL + sub_id;

  // $(function() {
  //   $('#details-card').card(options)
  // });

  function format(d) {
    // `d` is the original data object for the row
    // Modify this to display the details you want
    // Replace newline characters with HTML line breaks in candidate_profile
    console.log("formatting row data payload - d:");
    console.log(d);
    const formattedCandidateProfile = d.candidate_profile.replace(/\n/g, '<br>');
    const formattedSeekingBehaviors = d.job_seeking_strategy.replace(/\n/g, '<br>');
    
    return (
        '<div id="candidate-details-card" class="card card-transparent" data-pages="card">' +
          '<div class="card-header separator">' +
            '<div class="card-title">' + 'GenAI | Candidate Profile ' + '</div>' +
          '<div class="card-controls">' +
            // '<ul>' +
            //   '<li><a data-toggle="collapse" class="card-collapse show" href="#collapseCandidateDetails"><i class="card-icon card-icon-collapse"></i></a>' +
            //   '</li>' +
            // '</ul>' +
          '</div>' +
          '</div>' +
          '<div class="card-body" id="collapseCandidateDetails">' +
            '<h5 class="semi-bold p-t-10">Assessment and Guidance</h5>' +
            '<p class="">' + formattedCandidateProfile + '</p>' +
            // '<p>Cover Letter: ' + d.cover_letter + '</p>' +
            // Add more details here as needed
          '</div>' +
        '</div>' +
        '<div id="cover-letter-details-card" class="card card-default bg-contrast-lower" data-pages="card">' +
          '<div class="card-header separator">' +
            '<div class="card-title">' + 'GenAI | Job Seeking Tips ' + '</div>' +
          '<div class="card-controls">' +
            // '<ul>' +
            //   '<li><a data-toggle="collapse" class="card-collapse show" href="#candidateCoverLetterDetails"><i class="card-icon card-icon-collapse"></i></a>' +
            //   '</li>' +
            // '</ul>' +
          '</div>' +
          '</div>' +
          '<div class="card-body" id="candidateCoverLetterDetails">' +
            '<h5 class="semi-bold">Strategy and Tactics</h5>' +
            // '<p class="">' + d.candidate_profile + '</p>' +
            '<p>Job Seeking Strategy: ' + formattedSeekingBehaviors + '</p>' +
            // Add more details here as needed
          '</div>' +
        '</div>'
    );
  };

  

 


  let table = $('#coverLettersTable').DataTable({
    responsive: false,
    autoWidth: false,
    lengthChange: true,
    data: coverLetterData,
    columns: [
      {
        className: 'dt-control',
        orderable: false,
        data: null,
        title: 'Details',
        defaultContent: '', // You can use an icon/button here
        width: '10%'
      },
      {
        data: 'created_at',
        width: '10%',
        title: 'Date',
        // Use a visible column to display the formatted date
        render: function(data, type, row) {
          if (type === 'sort' || type === 'type') {
            // Return the original data for sorting purposes
            return data;
          } else {
            // Assuming `data` is a timestamp in milliseconds for display
            const date = new Date(data);
            return date.toLocaleDateString('en-US') + '-' + date.toLocaleTimeString('en-US');
          }
        }
      },
      
      // { data: 'updated_at', width: '10%', title:'Updated At'},
      // {
      //   data: 'cover_letter', 
      //   width: '42%', 
      //   title: 'Cover Letter',
      //   render: function (data, type, row) {
      //     if (type === 'display') {
      //       // Replace newline characters with HTML line breaks
      //       const formattedData = data.replace(/\n/g, '<br>');
      //       // Show only first 200 characters for display
      //       return formattedData.length > 500 ? formattedData.substr(0, 500) + '...' : formattedData;
      //     } else {
      //       // Return full data for other purposes like filtering and sorting
      //       return data;
      //     }
      //   }
      // },
      {
        data: 'candidate_name', 
        width: '20%', 
        title: 'Candidate',
        render: function (data, type, row) {
          if (type === 'display') {
            // Replace newline characters with HTML line breaks
            // const formattedData = data.replace(/\n/g, '<br>');
            // Show only first 200 characters for display
            const formattedData = data ? data : 'N/A';
            return formattedData;
          } else {
            // Return full data for other purposes like filtering and sorting
            return data ? data : '';
          }
        }
      },
      {
        data: 'company_name', 
        width: '20%', 
        title: 'Company',
        render: function (data, type, row) {
          if (type === 'display') {
            // Replace newline characters with HTML line breaks
            // const formattedData = data.replace(/\n/g, '<br>');
            // Show only first 200 characters for display
            const formattedData = data ? data : 'N/A';
            return formattedData;
          } else {
            // Return full data for other purposes like filtering and sorting
            return data ? data : '';
          }
        }
      },
      {
        data: 'job_fit_score',
        width: '10%',
        title: 'Job Fit Score',
        render: function (data, type, row) {
          if (type === 'display' && data) {
            // Convert string to float
            const score = parseFloat(data);
            let className = 'badge'; // Default class
            // Determine the class based on the score
            if (score < 5.5) {
              className += ' badge-danger';
            } else if (score >= 7.5) {
              className += ' badge-qualified';
            } else {
              className += ' '; // Use this for scores between 5.5 and 7.4
            }
      
            return `<div class="${className}">${score.toFixed(1)}</div>`; // toFixed(1) to show one decimal place
          } else {
            // Return full data for other purposes like filtering and sorting
            return data ? data : 'N/A';
          }
        }
      }
      ,
      { 
        data: 'job_title', 
        width: '30%', title: 'Job Title' },

      { title: 'Actions', width: '10%', render: function(data, type, row) {

        // Start the dropdown menu
        let actionsDropdown = `
          <div class="dropdown dropdown-default">
            <button aria-label="" class="btn dropdown-toggle" id="dropdownMenuButton${row.ravengen_id}" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Actions
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton${row.ravengen_id}">
        `;
    
        // Conditionally add the Visit URL link if the job_url exists and is not empty
        if (row.job_url) {
            actionsDropdown += `<a class="dropdown-item visit-url-link" href="#" data-url="${row.job_url}" data-id="${row.ravengen_id}">Visit URL</a>`;
        }
    
        // Continue with the rest of the menu items
        actionsDropdown += `
              <a class="dropdown-item copy-link" href="#" data-id="${row.ravengen_id}">Copy</a>
              <a class="dropdown-item delete-link" href="#" data-id="${row.ravengen_id}">Delete</a>
              <button id="ResumeButtonEdit" class="btn-xs btn btn-primary m-t-5 m-l-15 m-b-10 edit-link">Edit</button>
            </div>
          </div>
        `;
    
        return actionsDropdown;

      }},
    
      {
        data: 'ravengen_id',
        visible: false, // This makes the column not visible in the table
      },
      {
        data: 'cover_letter',
        visible: false, // This makes the column not visible in the table
      },
      {
        data: 'job_description',
        visible: false, // This makes the column not visible in the table
      },
      {
        data: 'job_seeking_strategy',
        visible: false, // This makes the column not visible in the table
      },
      {
        data: 'candidate_profile',
        visible: false, // This makes the column not visible in the table
      }
    ],
    order: [[1, 'desc']]
    
  });

  // Event listener for the "Visit URL" link
  $('#coverLettersTable tbody').on('click', '.visit-url-link', function(event) {
    event.preventDefault(); // Prevent the default link behavior

    // Retrieve the URL from the data-url attribute of the clicked link
    var joburl_data = table.row($(this).parents('tr')).data();
    const job_url = joburl_data.job_url;
    
    // Open the URL in a new tab
    window.open(job_url, '_blank');
  });


  $('#coverLettersTable tbody').on('click', '.copy-link', function(event) {
    event.preventDefault(); // Prevent the default anchor action
    // console.log("Cover Letter Copy Link Clicked.")
    var coverletter_data = table.row($(this).parents('tr')).data();
    const cover_letter = coverletter_data.cover_letter;
    // console.log(cover_letter);  // Corrected typo here
    copyCoverLetter(cover_letter);
    showNotification("Copied text to clipboard.", 'success');
  });

  $('#coverLettersTable tbody').on('click', '.delete-link', async function(event) {
    event.preventDefault(); // Prevent the default anchor action
    // console.log("Cover Letter Delete Link Clicked.")
    var coverletter_data = table.row($(this).parents('tr')).data();
    // console.log(coverletter_data)
    // const cover_letter_id = coverletter_data.coverletter_id;
    const created_at = coverletter_data.created_at;
    // console.log(created_at);  // Corrected typo here
    await deleteCoverLetter(created_at);
    
    table.row($(this).parents('tr')).remove().draw(false);
    showNotification("Cover letter deleted.", 'success');
    // var history = document.getElementById('history');
    // // spinner.style.display = 'block';
    // setTimeout(() => {
    //   history.scrollIntoView({ behavior: 'smooth' });
    // }, 0);
  });

  // Add event listener for opening and closing details
  $('#coverLettersTable tbody').on('click', 'tr', function () { //td.dt-control
    let tr = $(this).closest('tr');
    let row = table.row(tr);

    if (row.child.isShown()) {
      // This row is already open - close it
      row.child.hide();
      tr.removeClass('shown');
    }
    else {
      // Open this row
      row.child(format(row.data())).show();
      tr.addClass('shown');
    }
  });

  function copyCoverLetter(cover_letter) {
    if (cover_letter) {
      // Use Clipboard API to copy text
      navigator.clipboard.writeText(cover_letter)
        .then(() => {
          // console.log('Cover letter copied to clipboard!');
          // Optionally, you can show a notification to the user here
        })
        .catch(err => {
          // console.error('Error in copying text: ', err);
          // Optionally, handle the error case, perhaps show a notification
        });
    } else {
      // console.log('Cover letter text not found.');
      // Optionally, handle the case where the cover letter text is not found
    }
  }
  
  $('#coverLettersTable tbody').on('click', '.edit-link', function () {
    var data = table.row($(this).parents('tr')).data();
    // console.log("Data");
    // console.log(data);
    // console.log("cover letter text");
    // console.log(data.cover_letter);
    
    jQuery('#coverEditModal').modal('show')
    coverletterModalEditor.setText(data.cover_letter); 
    // alert("Resume Text to Edit: " + data.resume_text);
    //Add resume text to a quill editor with a button to generate a new document from the content
    
  });
  hidePreloader();

  

};

if (module.hot) {
  module.hot.accept();
}



// console.log("app.js finished...");







