

// // console.log("user_preferences.js loaded...");

// console.log("scripts loading...");

// import { Auth } from 'aws-amplify';

// let cachedSession = null;
// let cachedUserInfo = null;

// async function getCachedAuthInfo() {
//   if (!cachedSession || !cachedUserInfo) {
//     try {
//       cachedSession = await Auth.currentSession();
//       cachedUserInfo = await Auth.currentAuthenticatedUser();
//       // console.log("Cached Session and User Info:");
//       // console.log(cachedSession);
//       // console.log(cachedUserInfo);
//     } catch (error) {
//       // console.log('Error fetching auth info:', error);
//       throw error; // Rethrow error to handle it in the calling function
//     }
//   }
//   return { session: cachedSession, userInfo: cachedUserInfo };
// }

// export async function fetchDynamoDBItems() {
//   try {
//     console.log("Fetching items.")
//     const { session, userInfo } = await getCachedAuthInfo();
//     const userId = userInfo.attributes.sub;
    
//     const response = await fetch('https://vzh84fuw26.execute-api.us-west-2.amazonaws.com/Prod/upload', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `${session.getIdToken().getJwtToken()}`
//       },
//       body: JSON.stringify({ user_id: userId })
//     });

//     if (!response.ok) {
//       throw new Error(`Error fetching items: ${response.statusText}`);
//     }

//     const responseJson = await response.json();
//     // console.log("Items fetched:", responseJson);
//     return responseJson;
//   } catch (error) {
//     console.log('Error:', error);
//   }
// };

// export async function deleteCoverLetter(created_at) {
//   try {
//     const { session, userInfo } = await getCachedAuthInfo();
//     const userId = userInfo.attributes.sub;

//     const response = await fetch('https://muc2329sy8.execute-api.us-west-2.amazonaws.com/prod/delete', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `${session.getIdToken().getJwtToken()}`
//       },
//       body: JSON.stringify({ userId: userId, created_at: created_at })
//     });

//     if (!response.ok) {
//       throw new Error(`Error deleting item: ${response.statusText}`);
//     }

//     console.log("Item deleted successfully");
//   } catch (error) {
//     console.error('Error:', error);
//   }
// }

// export async function fetchUserPreferences() {
//   try {
//     const { session, userInfo } = await getCachedAuthInfo();
//     const userId = userInfo.attributes.sub;

//     const response = await fetch('https://gqkt4dk8h5.execute-api.us-west-2.amazonaws.com/prod/fetchPreferences', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `${session.getIdToken().getJwtToken()}`
//       },
//       body: JSON.stringify({ userId: userId })
//     });

//     if (!response.ok) {
//       throw new Error(`Error fetching user preferences: ${response.statusText}`);
//     }

//     const responseJson = await response.json();
//     // console.log("User preferences fetched:", responseJson);
//     return responseJson;
//   } catch (error) {
//     console.error('Error:', error);
//   }
// };

// // export async function updateUserPreferences(preferenceData) {
// //   try {
// //     const { session, userInfo } = await getCachedAuthInfo();
// //     const userId = userInfo.attributes.sub;

// //     const response = await fetch('https://your-api-endpoint.com/updatePreferences', {
// //       method: 'POST',
// //       headers: {
// //         'Content-Type': 'application/json',
// //         'Authorization': `${session.getIdToken().getJwtToken()}`
// //       },
// //       body: JSON.stringify({ userId: userId, ...preferenceData })
// //     });

// //     if (!response.ok) {
// //       throw new Error(`Error updating user preferences: ${response.statusText}`);
// //     }

// //     console.log("User preferences updated successfully");
// //   } catch (error) {
// //     console.error('Error:', error);
// //   }
// // }

// console.log("scripts loaded...");
// console.log("scripts loading...");

// import { Auth } from 'aws-amplify';

// let cachedSession = null;
// let cachedUserInfo = null;

// async function getCachedAuthInfo() {
//   if (!cachedSession || !cachedUserInfo) {
//     try {
//       cachedSession = await Auth.currentSession();
//       cachedUserInfo = await Auth.currentAuthenticatedUser();
//     } catch (error) {
//       throw error; // Rethrow error to handle it in the calling function
//     }
//   }
//   return { session: cachedSession, userInfo: cachedUserInfo };
// }

// export async function fetchDynamoDBItems() {
//   try {
//     console.log("Fetching items.")
//     const { session, userInfo } = await getCachedAuthInfo();
//     const userId = userInfo.attributes.sub;
    
//     const response = await fetch('https://vzh84fuw26.execute-api.us-west-2.amazonaws.com/Prod/upload', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `${session.getIdToken().getJwtToken()}`
//       },
//       body: JSON.stringify({ user_id: userId })
//     });

//     if (!response.ok) {
//       throw new Error(`Error fetching items: ${response.statusText}`);
//     }

//     const responseText = await response.text();
//     console.log("Response Text:", responseText);

//     try {
//       const responseJson = JSON.parse(responseText);
//       console.log("Items fetched:", responseJson);
//       return responseJson;
//     } catch (jsonError) {
//       console.error("Error parsing JSON:", jsonError);
//       throw new Error("Invalid JSON response");
//     }
//   } catch (error) {
//     console.log('Error:', error);
//   }
// };

// export async function deleteCoverLetter(created_at) {
//   try {
//     const { session, userInfo } = await getCachedAuthInfo();
//     const userId = userInfo.attributes.sub;

//     const response = await fetch('https://muc2329sy8.execute-api.us-west-2.amazonaws.com/prod/delete', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `${session.getIdToken().getJwtToken()}`
//       },
//       body: JSON.stringify({ userId: userId, created_at: created_at })
//     });

//     if (!response.ok) {
//       throw new Error(`Error deleting item: ${response.statusText}`);
//     }

//     console.log("Item deleted successfully");
//   } catch (error) {
//     console.error('Error:', error);
//   }
// }

// export async function fetchUserPreferences() {
//   try {
//     const { session, userInfo } = await getCachedAuthInfo();
//     const userId = userInfo.attributes.sub;

//     const response = await fetch('https://gqkt4dk8h5.execute-api.us-west-2.amazonaws.com/prod/fetchPreferences', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `${session.getIdToken().getJwtToken()}`
//       },
//       body: JSON.stringify({ userId: userId })
//     });

//     if (!response.ok) {
//       throw new Error(`Error fetching user preferences: ${response.statusText}`);
//     }

//     const responseText = await response.text();
//     console.log("Response Text:", responseText);

//     try {
//       const responseJson = JSON.parse(responseText);
//       console.log("User preferences fetched:", responseJson);
//       return responseJson;
//     } catch (jsonError) {
//       console.error("Error parsing JSON:", jsonError);
//       throw new Error("Invalid JSON response");
//     }
//   } catch (error) {
//     console.error('Error:', error);
//   }
// };

// console.log("scripts loaded...");

// console.log("scripts loading...");

// import { Auth } from 'aws-amplify';

// let cachedSession = null;
// let cachedUserInfo = null;

// async function getCachedAuthInfo() {
//   if (!cachedSession || !cachedUserInfo) {
//     try {
//       cachedSession = await Auth.currentSession();
//       cachedUserInfo = await Auth.currentAuthenticatedUser();
//     } catch (error) {
//       throw error; // Rethrow error to handle it in the calling function
//     }
//   }
//   return { session: cachedSession, userInfo: cachedUserInfo };
// }

// async function fetchWithRetries(url, options, retries = 3) {
//   for (let i = 0; i < retries; i++) {
//     try {
//       const response = await fetch(url, options);
//       if (!response.ok) {
//         throw new Error(`Error fetching items: ${response.statusText}`);
//       }
//       const responseText = await response.text();
//       const responseJson = JSON.parse(responseText);
//       return responseJson;
//     } catch (error) {
//       if (i < retries - 1) {
//         console.warn(`Retry ${i + 1}/${retries} failed. Retrying...`, error);
//       } else {
//         console.error("Max retries reached. Failing.", error);
//         throw error;
//       }
//     }
//   }
// }

// export async function fetchDynamoDBItems() {
//   try {
//     console.log("Fetching items.");
//     const { session, userInfo } = await getCachedAuthInfo();
//     const userId = userInfo.attributes.sub;

//     const responseJson = await fetchWithRetries('https://vzh84fuw26.execute-api.us-west-2.amazonaws.com/Prod/upload', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `${session.getIdToken().getJwtToken()}`
//       },
//       body: JSON.stringify({ user_id: userId })
//     });

//     console.log("Items fetched:", responseJson);
//     return responseJson;
//   } catch (error) {
//     console.error('Error:', error);
//   }
// };

// export async function deleteCoverLetter(created_at) {
//   try {
//     const { session, userInfo } = await getCachedAuthInfo();
//     const userId = userInfo.attributes.sub;

//     const response = await fetch('https://muc2329sy8.execute-api.us-west-2.amazonaws.com/prod/delete', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `${session.getIdToken().getJwtToken()}`
//       },
//       body: JSON.stringify({ userId: userId, created_at: created_at })
//     });

//     if (!response.ok) {
//       throw new Error(`Error deleting item: ${response.statusText}`);
//     }

//     console.log("Item deleted successfully");
//   } catch (error) {
//     console.error('Error:', error);
//   }
// }

// export async function fetchUserPreferences() {
//   try {
//     const { session, userInfo } = await getCachedAuthInfo();
//     const userId = userInfo.attributes.sub;

//     const responseJson = await fetchWithRetries('https://gqkt4dk8h5.execute-api.us-west-2.amazonaws.com/prod/fetchPreferences', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `${session.getIdToken().getJwtToken()}`
//       },
//       body: JSON.stringify({ userId: userId })
//     });

//     console.log("User preferences fetched:", responseJson);
//     return responseJson;
//   } catch (error) {
//     console.error('Error:', error);
//   }
// };

// console.log("scripts loaded...");

console.log("scripts loading...");

import { Auth } from 'aws-amplify';

let cachedSession = null;
let cachedUserInfo = null;

export async function getCachedAuthInfo() {
  if (!cachedSession || !cachedUserInfo) {
    try {
      cachedSession = await Auth.currentSession();
      console.log("Session Credentials");
      console.log(cachedSession);
      cachedUserInfo = await Auth.currentAuthenticatedUser();
      console.log(cachedSession);

    } catch (error) {
      throw error; // Rethrow error to handle it in the calling function
    }
  }
  return { session: cachedSession, userInfo: cachedUserInfo };
}

async function fetchWithRetries(url, options, retries = 3) {
  for (let i = 0; i < retries; i++) {
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`Error fetching items: ${response.statusText}`);
      }
      const responseText = await response.text();
      console.log("Raw response text:", responseText);

      if (!responseText) {
        throw new Error("Empty response body");
      }

      const responseJson = JSON.parse(responseText);
      return responseJson;
    } catch (error) {
      if (i < retries - 1) {
        console.warn(`Retry ${i + 1}/${retries} failed. Retrying...`, error);
      } else {
        console.error("Max retries reached. Failing.", error);
        throw error;
      }
    }
  }
}

// export async function fetchDynamoDBItems() {
//   try {
//     console.log("Fetching items.");
//     const { session, userInfo } = await getCachedAuthInfo();
//     const userId = userInfo.attributes.sub;
//     console.log("User ID:", userId);
//     // const responseJson = await fetchWithRetries('https://vzh84fuw26.execute-api.us-west-2.amazonaws.com/Prod/upload', {
//     //   method: 'POST',
//     //   headers: {
//     //     'Content-Type': 'application/json',
//     //     'Authorization': `${session.getIdToken().getJwtToken()}`
//     //   },
//     //   body: JSON.stringify({ user_id: userId })
//     // });

//     const responseJson = await fetch('https://vzh84fuw26.execute-api.us-west-2.amazonaws.com/Prod/upload', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `${session.getIdToken().getJwtToken()}`
//       },
//       body: JSON.stringify({ user_id: userId })
//     });

//     console.log("Items fetched:", responseJson);
//     return responseJson;
//   } catch (error) {
//     console.error('Error:', error);
//   }
// }

export async function fetchDynamoDBItems() {
  try {
    console.log("Fetching items.");
    const { session, userInfo } = await getCachedAuthInfo();
    const userId = userInfo.attributes.sub;

    const response = await fetch('https://vzh84fuw26.execute-api.us-west-2.amazonaws.com/Prod/upload', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${session.getIdToken().getJwtToken()}`
      },
      body: JSON.stringify({ user_id: userId })
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseJson = await response.json();

    if (!responseJson || Object.keys(responseJson).length === 0) {
      throw new Error('Empty response');
    }

    console.log("Items fetched:", responseJson);
    return responseJson;
  } catch (error) {
    console.error('Error:', error);
    // Display an alert in the UI
    alert('An error occurred while fetching items: ' + error.message);
    // Return a default value or handle the error as needed
    return null;
  }
}


export async function deleteCoverLetter(created_at) {
  try {
    const { session, userInfo } = await getCachedAuthInfo();
    const userId = userInfo.attributes.sub;

    const response = await fetch('https://muc2329sy8.execute-api.us-west-2.amazonaws.com/prod/delete', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${session.getIdToken().getJwtToken()}`
      },
      body: JSON.stringify({ userId: userId, created_at: created_at })
    });

    if (!response.ok) {
      throw new Error(`Error deleting item: ${response.statusText}`);
    }

    console.log("Item deleted successfully");
  } catch (error) {
    console.error('Error:', error);
  }
}

export async function fetchUserPreferences() {
  try {
    const { session, userInfo } = await getCachedAuthInfo();
    const userId = userInfo.attributes.sub;

    const responseJson = await fetchWithRetries('https://gqkt4dk8h5.execute-api.us-west-2.amazonaws.com/prod/fetchPreferences', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${session.getIdToken().getJwtToken()}`
      },
      body: JSON.stringify({ userId: userId })
    });

    console.log("User preferences fetched:", responseJson);
    return responseJson;
  } catch (error) {
    console.error('Error:', error);
  }
}

console.log("scripts loaded...");
