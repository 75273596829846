// console.log("upload.js loading...");

import jQuery from "jquery";
import DataTable from 'datatables.net-dt';
import 'datatables.net-buttons-dt';
import 'datatables.net-searchpanes-dt';
import Quill from 'quill';
import html2pdf from 'html2pdf.js';
import jsPDF from 'jspdf';
import { Auth, Storage } from 'aws-amplify';

const finalCoverLetter = new Quill('#finalCoverLetter', {
  theme: 'snow'
});


const uploadForm = document.getElementById('uploadForm'); //Encompasses the tab 1 contents for uploading and selecting the resume document
const resumeInput = document.getElementById('resumeInput'); //Drives the document selection from local storage
const fileName = document.getElementById('fileName');

const jobDescriptionEditor = document.getElementById('jobDescriptionEditor')
// const finalCoverLetter = new Quill('#finalCoverLetter', { theme: 'snow' });
const responseContainer = document.getElementById('responseContainer');
const spinner = document.getElementById('spinner');
const nextButton = document.getElementById('nextButton');
const generateContentButton = document.getElementById('generateContentButton');
const saveButton = document.getElementById('saveButton');
// const finalCoverLetter = document.getElementById('finalCoverLetter');
const finalSummary = document.getElementById('finalSummary');
const extractedText = document.getElementById('extractedText');
// const spinners = document.querySelectorAll('.spinner');
// const viewPrepButton = document.getElementById('viewPrepButton');
const accordion = document.getElementById('accordion');
const collapseOne = document.getElementById("collapseOne");
const uploadresumetick = document.getElementById("uploadresumetick");
const collapseTwo = document.getElementById("collapseTwo");
const jobdescriptiontick = document.getElementById("jobdescriptiontick");

const downloadButton = document.getElementById('downloadButton');
const deleteButton = document.getElementById('deleteButton');

// WebSocket handlers...

const apiGatewayWebSocketUrl = "wss://84ikzadlw2.execute-api.us-west-2.amazonaws.com/prod";
// let webSocket = new WebSocket(apiGatewayWebSocketUrl);

let s3Key;
let selectedFiles = {}; //This is in reference to the files selected for upload from the local filestore
// let userId;  // Declare userId here?
let selectedFileKey = null; //This creates a reference to the file selected after upload is complete.


// Global variable to store the selected file's key and URL
let selectedFile = { key: null, url: null };


window.updateCharacterCount = function() {
  var text = document.getElementById('jobDescriptionEditor').value;
  var charCount = text.length;
  document.getElementById('charCount').textContent = charCount;
};


export async function setUserId() {
    try {
        const user = await Auth.currentAuthenticatedUser();
        userId = user.attributes.sub;
        console.log("User ID set:", userId);
        // Call it when your module is initialized or after userId has been set
        loadUploadedFiles();
        return userId;
    } catch (error) {
        // console.error("Error fetching authenticated user:", error);
        // showNotification("No user authenticated.", 'error');
    }
}


export async function setCognitoIdentityId() {
    try {
        const credentials = await Auth.currentCredentials();
        cognitoIdentityId = credentials.identityId;
        console.log("Cognito Identity ID set:", cognitoIdentityId);
        return cognitoIdentityId;
    } catch (error) {
        console.error("Error fetching Cognito Identity ID:", error);
    }
}
let userId;
userId = setUserId();

let cognitoIdentityId;
cognitoIdentityId = setCognitoIdentityId();

export function showNotification(message, type, style = 'circle', position = 'top-right') {
  $('.page-content-wrapper ').pgNotification({
      title: "Notification",
      style: style,
      message: message,
      position: position,
      type: type,
      timeout: 4000, // Adjust timeout as needed
      thumbnail: '<img width="40" height="40" style="display: inline-block;" src="assets/img/cloudraven-logo-only.svg" data-src="assets/img/cloudraven-logo-only.svg" data-src-retina="assets/img/cloudraven-logo-only.svg" alt="">'
  }).show();
}


async function loadUploadedFiles() {
  try {
    
    const response = await Storage.list(`${userId}/`, { level: 'private' });
    console.log('Result from Storage.list:', response);

    const uploadedFilesContainer = document.getElementById('uploadedFilesContainer');
    uploadedFilesContainer.innerHTML = ''; // Clear any existing contents

    let isFirstFile = true; // Flag to check if it's the first file

    // Iterate over the files in the 'results' array
    for (const file of response.results) {
      const fileKey = file.key;
      const fileUrl = await Storage.get(fileKey, { level: 'private' });
      console.log("fileURL")
      console.log(fileUrl)
      // Create elements for each file
      const divFormCheck = document.createElement('div');
      divFormCheck.className = 'form-check success';

      const radioButton = document.createElement('input');
      radioButton.type = 'radio';
      radioButton.name = 'selectedFile';
      radioButton.value = fileUrl; // Use fileUrl instead of fileKey
      radioButton.id = `file${fileKey}`;
      radioButton.className = '';
      radioButton.addEventListener('change', () => {
        // selectedFileKey = fileKey; // Keep track of the selected file key
        // console.log("Selected FileKey to Send to API - stored as Global Variable:", selectedFileKey);
        selectedFile = { key: fileKey, url: fileUrl }; // Store both key and URL
        // console.log("Selected File:", selectedFile);
        nextButton.style.display = 'block';
        downloadButton.style.display = 'block';
        deleteButton.style.display = 'block';
      });

      // If it's the first file, check the radio button
      if (isFirstFile) {
        selectedFile = { key: fileKey, url: fileUrl }; // Store both key and URL
        console.log("Selected File:", selectedFile);
        radioButton.checked = true;
        nextButton.style.display = 'block';
        downloadButton.style.display = 'block';
        deleteButton.style.display = 'block';
        isFirstFile = false; // Update the flag so only the first radio button gets checked
      }

      // const label = document.createElement('label');
      // label.htmlFor = `file${fileKey}`;
      // label.textContent = fileKey; // Display the file key

      const label = document.createElement('label');
      label.htmlFor = `file${fileKey}`;

      // Split the fileKey by '/' and get the last part
      const filename = fileKey.split('/').pop();
      label.textContent = filename; // Display only the filename

      label.style.overflowWrap = "break-word";
      label.style.whiteSpace = "normal";
      label.style.display = "block";
      label.style.width = "100%";



      divFormCheck.appendChild(radioButton);
      divFormCheck.appendChild(label);
      uploadedFilesContainer.appendChild(divFormCheck);
    }
  } catch (err) {
    console.error("Error fetching files:", err);
    showNotification("Error fetching authenticated user: " + err, 'error');

    // showErrorAlert("Failed to load uploaded files."); // if you have an error handling function
  }
}

async function sendCoverLetterToLambda(coverLetterText) {
  try {
    // console.log("cover letter text from quill editor:")
    //   console.log(coverLetterText)
    userId = await setUserId();
    cognitoIdentityId = await setCognitoIdentityId();
    console.log("User Id set for cover letter generation:");
    console.log(userId);
    console.log(cognitoIdentityId);
    const session = await Auth.currentSession();
    // const userId = session.idToken.payload.sub;
    const coverLetterText = finalCoverLetter.getText();
    console.log("cover letter text from quill:")
    console.log(coverLetterText)
    const response = await fetch('https://muc2329sy8.execute-api.us-west-2.amazonaws.com/prod/docgen', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': session.getIdToken().getJwtToken()
        },
        body: JSON.stringify({
            user_Id: userId,
            coverLetter: coverLetterText,
            identity_id: cognitoIdentityId
        })
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    
    const responseData = await response.json();
    // console.log('Response from Lambda:', responseData);
    
    // Handle response data (like showing a success message or a link to the generated document)
    // Extract the download link from the response
    const downloadLink = JSON.parse(responseData.body).download_link;

    // Create an anchor tag for the download link
    const link = document.createElement('a');
    link.href = downloadLink;
    link.textContent = 'Download Cover Letter';
    link.download = 'cover_letter.docx';  // Suggests a download filename

    // Append the link to the document, for example, to a specific div
    document.getElementById('download-container').appendChild(link);

  } catch (error) {
      console.error('Error sending cover letter to Lambda:', error);
      showNotification("Error sending cover letter to Lambda: " + error, 'error');

  }
}

async function sendCoverLetterEditToLambda(coverLetterText) {
  try {
    // console.log("cover letter text from quill editor:")
    //   console.log(coverLetterText)
      const session = await Auth.currentSession();
      const userId = session.idToken.payload.sub;
      // const coverLetterText = finalCoverLetter.getText();
      // console.log("cover letter text from quill:")
      // console.log(coverLetterText)
      const response = await fetch('https://muc2329sy8.execute-api.us-west-2.amazonaws.com/prod/docgen', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': session.getIdToken().getJwtToken()
          },
          body: JSON.stringify({
              user_Id: userId,
              coverLetter: coverLetterText,
              identity_id: cognitoIdentityId
          })
      });

      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const responseData = await response.json();
      // console.log('Response from Lambda:', responseData);
      
      // Handle response data (like showing a success message or a link to the generated document)
      // Extract the download link from the response
      const downloadLink = JSON.parse(responseData.body).download_link;

      // Create an anchor tag for the download link
      const link = document.createElement('a');
      link.href = downloadLink;
      link.textContent = 'Download Cover Letter';
      link.download = 'cover_letter.docx';  // Suggests a download filename

      // Append the link to the document, for example, to a specific div
      document.getElementById('edit-download-container').appendChild(link);

  } catch (error) {
      console.error('Error sending cover letter to Lambda:', error);
  }
}

// Add this function call to an event listener or wherever you need to trigger the document creation
// sendCoverLetterToLambda();

function handleResponse(response) {
  // Assuming 'response' is the JSON string you received
  const data = JSON.parse(response);
  const downloadLink = data.body.download_link;

  // Create an anchor tag and set attributes
  const link = document.createElement('a');
  link.href = downloadLink;
  link.textContent = 'Download Cover Letter';
  link.download = 'cover_letter.docx';  // Suggests a download filename

  // Append the link to the document, for example, to a specific div
  document.getElementById('download-container').appendChild(link);
}

nextButton.addEventListener('click', async () => {
  if (selectedFile.key) {
    collapseOne.classList.toggle("show");
    uploadresumetick.style.display = "block";
    const descForm = document.getElementById('descriptionForm');
    descForm.style.display = 'block';
    document.getElementById('ravengen_step2').style.display = "flex";
    collapseTwo.classList.toggle("show");

    setTimeout(() => {
      accordion.scrollIntoView({ behavior: 'smooth' });
    }, 50);

    try {
      let session = await Auth.currentSession();
      userId = session.idToken.payload.sub;
      let credentials = await Auth.currentCredentials();
      let cognitoIdentityId = credentials.identityId;
      console.log("Cognito Identity ID set:", cognitoIdentityId);

      const response = await fetch('https://jm6277lir8.execute-api.us-west-2.amazonaws.com/prod/fetchListings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${session.getIdToken().getJwtToken()}`
        },
        body: JSON.stringify({ 
          user_id: userId,
          action: 'listingsFetch',
          s3_key: selectedFile.key,
          identity_id: cognitoIdentityId 
        })
      });

      if (!response.ok) {
        throw new Error(`Error fetching items: ${response.statusText}`);
      };

      let matchesJson = await response.json();
      // Parsing the body to get the matches array
      let parsedBody = JSON.parse(matchesJson.body);
      let matches = parsedBody.matches;
      console.log("Matches:",matches);

      const listingsContainer = document.getElementById('amazonjobs');
      listingsContainer.innerHTML = '';

      matches.forEach((match) => {
        const listingElement = document.createElement('div');
        listingElement.classList.add('listing');
        listingElement.classList.add('b-b');
        listingElement.classList.add('b-grey');
        listingElement.classList.add('m-b-5');
        

        listingElement.innerHTML = `
          <h5 class="semi-bold no-padding no-margin">${match.metadata.company_name}</h5>
          <p class="no-padding no-margin"><a href="${match.metadata.job_url}" target="_blank">${match.metadata.job_title}</a></p>
          <p class="no-padding no-margin">${match.metadata.location} - ${match.metadata.posted_date}</p>
          <p class="no-padding no-margin"><span>$</span>${match.metadata.minimum_salary} - <span>$</span>${match.metadata.maximum_salary}</p>
          <p class="no-padding no-margin"><span>Travel Required: </span>${match.metadata.travel_required} - <span>Years Experience: </span>${match.metadata.required_years_experience}</p>
          <div class="row padding-5">
          <button class="btn btn-xs btn-outline-primary detailsButton m-b-10 m-l-20">Job Details</button>
          <button class="btn btn-xs btn-success injectButton m-b-10 m-l-5">Use Description</button>
          </div>
          <div class="details padding-10" style="display: none;">
            <p>${match.metadata.concatenated_text}</p>
          </div>
        `;

        listingsContainer.appendChild(listingElement);
        
        // Corrected the query selector to find '.detailsButton'
        listingElement.querySelector('.detailsButton').addEventListener('click', function() {
          const detailsDiv = this.parentNode.parentNode.querySelector('.details');
          detailsDiv.style.display = detailsDiv.style.display === 'none' ? 'block' : 'none';
        });
        

        // Event listener for the new "Use Description" button
        listingElement.querySelector('.injectButton').addEventListener('click', function() {
          // This now correctly navigates to the parent listingElement and then finds the .details div
          const detailsDiv = this.closest('.listing').querySelector('.details');
          const jobDetails = detailsDiv.innerText; // Gets the text correctly now
          const jobDescriptionEditor = document.getElementById('jobDescriptionEditor');
          jobDescriptionEditor.value = jobDetails; // Injects the job details into the textarea
          document.getElementById('joburl').value = match.metadata.job_url;
        });
        
      });
    } catch (error) {
      console.log('Error:', error);
    };
  } else {
    console.error("No file selected");
  };
});


async function uploadFile(file) {
  try {
      // Include the userId in the file path
      const filePath = `${userId}/${file.name}`;
      const result = await Storage.put(filePath, file, {
          contentType: file.type,
          level: 'private' // Set the level to 'private' if the files should be user-specific and private
      });
      // console.log('Uploaded file:', result);
      return result;
  } catch (error) {
      console.error('Error uploading file:', error);
      showNotification("Error uploading file(s)", 'error');
      // showErrorAlert("Failed to upload file.");
  }
}

document.getElementById('resumeInput').addEventListener('change', function() {
  console.log('File input changed');
});

document.querySelector('label[for="resumeInput"]').addEventListener('click', function() {
  console.log('Label clicked');
});

let messageChunks = '';

function formatText(text) {
  // Replace **bold** with <strong>bold</strong>
  let formattedText = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  // Replace ### heading with <h2>heading</h2>
  formattedText = formattedText.replace(/###\s*(.*?)(\r?\n|$)/g, '<h4>$1</h4>');
  // Replace ## heading with <h3>heading</h3>
  formattedText = formattedText.replace(/##\s*(.*?)(\r?\n|$)/g, '<h3>$1</h3>');
  // Replace # heading with <h1>heading</h1>
  formattedText = formattedText.replace(/#\s*(.*?)(\r?\n|$)/g, '<h2>$1</h2>');
  // Replace *italic* with <em>italic</em>
  formattedText = formattedText.replace(/\*(.*?)\*/g, '<em>$1</em>');
  // Replace - item with <li>item</li> inside <ul>
  formattedText = formattedText.replace(/^- (.*?)(\r?\n|$)/gm, '<li>$1</li>');
  // Wrap <li> items in <ul>
  formattedText = formattedText.replace(/(<li>.*?<\/li>)/g, '<ul>$1</ul>');
  // Replace newline characters with <br> tags
  formattedText = formattedText.replace(/\n/g, '<br>');
  return formattedText;
}

class WebSocketManager {
  constructor(url, userID) {
      this.url = url;
      this.userID = userID;
      this.ws = null;
      this.messageQueue = [];
      this.isConnected = false;

      this.reconnectInterval = 5000;  // Time to wait before reconnect (in milliseconds)
      this.connect();
  }
  
  connect() {
      this.ws = new WebSocket(this.url);
      this.ws.onopen = () => {
          console.log("WebSocket connected!");
          this.isConnected = true;
          this.flushQueue();
      };
      this.ws.onclose = (event) => {
          console.log("WebSocket disconnected!", event.reason);
          this.isConnected = false;
          setTimeout(() => this.connect(), this.reconnectInterval);  // Attempt to reconnect
      };
      this.ws.onmessage = (message) => {
          
          console.log("Received message:", message.data);
          // showSpinners();
          const data = JSON.parse(message.data);

          switch(data.type) {
            
           
            // case 'responseChunk':
            //   console.log("Raven Response Chunk");
            //   console.log(data.message);
            //   let messagechunktext = data.message;
            //   // Convert markdown-like syntax to HTML for the accumulated chunks
            //   let formattedMessage = messagechunktext
            //       .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')  // Replace **bold** with <strong>bold</strong>
            //       .replace(/###\s*(.*?)(\r?\n|$)/g, '<h2>$1</h2>');  // Replace ### heading with <h2>heading</h2>
            //   formattedMessage = formattedMessage.replace(/\n/g, '<br>');
            //   console.log("Formatted Message:");
            //   console.log(formattedMessage);
            //   // Accumulate chunks of the message
            //   messageChunks += formattedMessage;
              
            //   // Convert markdown-like syntax to HTML for the accumulated chunks
            //   // const formattedMessage = messageChunks
            //   //     .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')  // Replace **bold** with <strong>bold</strong>
            //   //     .replace(/###\s*(.*?)(\r?\n|$)/g, '<h2>$1</h2>');  // Replace ### heading with <h2>heading</h2>
              
            //   // Display the accumulating message chunks in real-time
            //   candidateprofilesummary.style.display = 'block';
            //   document.getElementById('candidateProfileMessage').innerHTML = messageChunks;
              
            //   break;

            case 'responseChunk':
              console.log("Raven Response Chunk");
              console.log(data.message);
              messageChunks += data.message;
              const formattedMessage = formatText(messageChunks);
              console.log("Formatted Message:");
              console.log(formattedMessage);
              candidateprofilesummary.style.display = 'block';
              document.getElementById('candidateProfileMessage').innerHTML = formattedMessage;
              break;


            case 'textExtracted':
              console.log("textExtract completed")
              console.log(data.type)
              break;

            case 'generationComplete':
              if (typeof data.message === 'string') {
                  // const coverLetterTextarea = document.getElementById('finalCoverLetter');
                  // coverLetterTextarea.setAttribute('rows', 10);
                  // document.getElementById('finalCoverLetter').value = data.message;
                  finalCoverLetter.setText(data.message);
                  // finalCoverLetter.insertText(data.message);
                  // hideSpinners()
                  coverletterspinner.style.display = "none";
                  coverlettertick.style.display = "block";
                  
              } else {
                  console.error("Cover letter text is undefined or not present in the response.");
              }
              
              break;
            
              case 'interviewPrepComplete':
                if (data.message) {
                  const interviewPrepMessage = document.getElementById('interviewPrepMessage');
                  interviewPrepMessage.innerHTML = data.message.replace(/\n/g, '<br>'); // Replace newline characters with HTML line breaks
                  // hideSpinners();
                  interviewprepspinner.style.display = "none";
                  interviewpreptick.style.display = "block";
                } else {
                  console.error("Job strategy text is undefined or not present in the response.");
                }
                break;

                case 'candidateProfileAssessmentUpdate':
                  console.log("Candidate profile and job description extraction initiated.");
                  console.log(data.message);
                  break;
              
                case 'candProfileComplete':
                  if (data.message) {
                    console.log("Candidate Profile Summary Payload Message");
                    console.log(data);
                    // const candidateProfileMessage = document.getElementById('candidateProfileMessage');
                    // candidateProfileMessage.innerHTML = data.message.replace(/\n/g, '<br>'); // Replace newline characters with HTML line breaks
                  } else {
                    console.error("Job strategy text is undefined or not present in the response.");
                  }
                  break;

                  case 'candProfileCompleted':
                  if (data.type === "candProfileCompleted" && data.message.length > 0) {
                    var candidateInfo = data.message[0]; // Access the first item in the message array
                    console.log(candidateInfo);
                    // Convert job_fit_score from string to number
                    const jobFitScore = parseFloat(candidateInfo.job_fit_score);
                    // Select the label_score element
                    
                    // Check if jobFitScore is equal to or above 7.5 and update class
                    if (jobFitScore >= 7.5) {
                      document.getElementById('badge_score').className = 'badge badge-qualified padding-35'; // Update to success class
                    } else {
                      // Optional: handle other cases or leave as default
                      document.getElementById('badge_score').className = 'badge padding-35'; // Reset to default or other class as needed
                    };
                    document.getElementById('candidate_name_text').innerHTML = candidateInfo.candidate_name;
                    document.getElementById('candidate_summary_text').innerHTML = candidateInfo.resume_career_summary.replace(/\n/g, '<br>');
                    document.getElementById('job_listing_summary_text').innerHTML = candidateInfo.job_description.replace(/\n/g, '<br>');
                    document.getElementById('job_title_text').innerHTML = candidateInfo.job_description_job_title;
                    document.getElementById('company_name_text').innerHTML = candidateInfo.job_description_company_name;
                    document.getElementById('resume_score_text').innerHTML = candidateInfo.job_fit_score;
                    document.getElementById('scoring_content').innerHTML = candidateInfo.scoring_content;
                    document.getElementById('years_experience_text').innerHTML = candidateInfo.job_description_required_years_experience;
                    document.getElementById('candidate_summary_card').style.display = "block";
                    document.getElementById('candidateprofilesummary').style.display = "block";
                    document.getElementById('candidate_profile_generation_usermessage').style.display = "none";
                    document.getElementById('spinner').style.display = "none";
                    candidateprofilespinner.style.display = "none";
                    candidateprofiletick.style.display = "block";

                  } else {
                    console.error("Data type mismatch or empty message array.");
                  }
                  break;


                  case 'ravenGoogleSearchGPT':
                  const container = document.getElementById('ravenGoogleSearchGPT_results');
                  container.innerHTML = ''; // Clear existing content

                  // Check if data.message is empty
                  if (data.message.length === 0) {
                      const noResultsElement = document.createElement('div');
                      noResultsElement.style.padding = "10px";
                      noResultsElement.innerHTML = `<div class="padding-10 bg-contrast-lower"><div class="card-body padding-20"><h5 class="semi-bold no-padding no-margin">No immediate contacts found for this role. Please try again with a different job description.</h5></div></div>`;
                      container.appendChild(noResultsElement);
                  } else {
                      console.log("Search Results:");
                      console.log(data.message);
                      
                      data.message.forEach(item => {
                          const element = document.createElement('div');
                          element.style.paddingBottom = "10px";
                          element.innerHTML = `
                              <div class="padding-10 bg-contrast-lower"
                              <div class="card-body padding-20">
                              <h5 class="semi-bold no-padding no-margin">${item.contact_name}</h5>
                              <p class="">${item.title}</p>
                              <p class="no-margin no-padding"><a href="${item.link}" target="_blank">${item.link}</a></p>
                              <p class="no-margin no-padding p-t-5 semi-bold">Summary: <span class="light"> ${item.snippet} </span></p>
                              <p class="no-margin no-padding p-t-5 semi-bold">Networking Suggestions: <span class="light"> ${item.networking_suggestions} </span></p>
                              <p class="no-margin no-padding p-t-5 semi-bold">Recommended Ice-Breaker: <span class="light"> ${item.recommended_question} </span></p>
                              </div>
                              </div>
                          `;
                          container.appendChild(element);
                      });
                  }
                  document.getElementById('networkingtick').style.display = "block";
                  document.getElementById('networkingspinner').style.display = "none";
                  break;

                  
            // Handle other message types if necessary
          }
          // document.getElementById('messages').value += message.data + '\\n';
      };
      this.ws.onerror = (error) => {
          console.error("WebSocket error:", error);
          this.ws.close();  // Ensures triggering the onclose handler
      };
  }

  send(message) {
      if (this.isConnected) {
          this.ws.send(message);
      } else {
          console.log("WebSocket not connected. Queuing message.");
          this.messageQueue.push(message);
      }
  }

  flushQueue() {
      while (this.messageQueue.length > 0 && this.isConnected) {
          const message = this.messageQueue.shift();
          this.ws.send(message);
      }
  }
}


const websocketUrl = 'wss://84ikzadlw2.execute-api.us-west-2.amazonaws.com/prod';  // WebSocket URL
const wsManager = new WebSocketManager(websocketUrl, userId);

// function sendMessage() {
//   const message = document.getElementById('messageInput').value;
//   const userId = '396d3f98-31a4-44a4-8af9-fb416339b7b7'; // This should be your authenticated user's ID
//   let selectedFile = '396d3f98-31a4-44a4-8af9-fb416339b7b7/Robert-Schaper-Resume-2024.pdf';
//   let jobDescriptionFormattedText = "This is a classic job listing for a bartender in the Seattle area who likes to work nights and weekends in the Seattle area, needs to be a great listener and good with data and money.";
//   let cognitoIdentityId = 'us-west-2:5423b316-cbb9-c5f0-f5f2-1586ed014cb6';
//   job_url = 'https://www.somecomepany.com';
//   const data = {
//       action: 'generateContent',
//       s3_key: selectedFile,
//       job_description_text: message,
//       job_url: job_url,
//       user_id: userId,
//       identity_id: cognitoIdentityId
//   };
//   wsManager.send(JSON.stringify(data));
//   document.getElementById('messageInput').value = '';  // Clear the input after sending
// }

// Modify the change event handler for resumeInput
resumeInput.addEventListener('change', async () => {
  const files = resumeInput.files;
  if (files.length > 0) {
      selectedFiles = Array.from(files);
      // console.log("Selected Files:", selectedFiles);
      fileName.textContent = selectedFiles.map(file => file.name).join(', ');

      // Automatically start uploading files after selection
      for (const file of selectedFiles) {
          await uploadFile(file);
      }
      
      // Refresh the list of uploaded files
      await loadUploadedFiles();
      showNotification("File(s) uploaded.", 'success');
  } else {
      fileName.textContent = 'No file uploaded.';
  }
});

// Job description input and cover letter generation
generateContentButton.addEventListener('click', async () => {
  messageChunks = '';
  const jobDescriptionText = jobDescriptionEditor.value;
  const jobDescriptionFormattedText = jobDescriptionText.replace(/(\r\n|\n|\r)/gm, ' ');
  const session = await Auth.currentSession();
  userId = session.idToken.payload.sub;
  const credentials = await Auth.currentCredentials();
  const cognitoIdentityId = credentials.identityId;
  console.log("Cognito Identity ID set:", cognitoIdentityId);
  let job_url = document.getElementById('joburl').value;
  document.getElementById('candidateProfileMessage').textContent = ''; // Clear any existing contents
  document.getElementById('candidateProfileMessage').innerHTML = ''; // Clear any existing contents
  document.getElementById('candidateProfileMessage').replaceChildren();

  const data = {
    action: 'generateContent',
    s3_key: selectedFile.key,
    job_description_text: jobDescriptionFormattedText,
    job_url: job_url,
    user_id: userId,
    identity_id: cognitoIdentityId
  };
  console.log("Data Payload:")
  console.log(data)
  spinner.style.display = 'flex';
  collapseTwo.classList.toggle("show");

  console.log("Sending data to WebSocket.");
  // webSocket.send(JSON.stringify(data));
  wsManager.send(JSON.stringify(data));
  console.log("Data sent to WebSocket.");
  
  jobdescriptiontick.style.display = "block"; // Show
  collapseTwo.classList.toggle("show");
  document.getElementById('ravengen_step3').style.display = "block";
  document.getElementById('ravengen_step4').style.display = "block";
  document.getElementById('ravengen_step5').style.display = "block";
  document.getElementById('ravengen_step6').style.display = "block";
  document.getElementById('networkingspinner').style.display = "block";
  candidateprofilespinner.style.display = "block";
  coverletterspinner.style.display = "block";
  interviewprepspinner.style.display = "block";
  collapseThree.classList.toggle("show");
  // Hide the current form (Step 2) and Show the loading spinner
  // document.getElementById('descriptionForm').style.display = 'none';
  // spinner.style.display = 'block';
  collapseTwo.classList.toggle("show");
  setTimeout(() => {
    accordion.scrollIntoView({ behavior: 'smooth' });
  }, 50);
});

//When the saveButton is clicked, send the value of the textarea the user is editing and send the text payload with correct formatting to a function/service
//The service will sit behind an API and work on the user behalf to generate a document with predefined formatting, save it to the user's private s3 bucket, and send a response with a link for the user to download the draft after its creation
//The service could optionally send a link to the user which expires (pre-signed url) when completed.

saveButton.addEventListener('click', async () => {
  //Add this function call to an event listener or wherever you need to trigger the document creation
  const coverLetterText = finalCoverLetter.getText();
  // console.log("cover letter text from quill:")
  // console.log(coverLetterText)
  const docgenspinner1 = document.getElementById('docgenspinner1');
  docgenspinner1.style.display = "block"; // Show
  // console.log(docgenspinner1);
  // console.log("Displaying spinner?");
  try {

    await sendCoverLetterToLambda(coverLetterText);
  } catch (error) {
      console.error('Error in sendCoverLetterToLambda:', error);
      showNotification("Error in sending cover letter to raven: " + err, 'error');
  } finally {
      docgenspinner1.style.display = "none"; // Hide spinner after async operation
  }

});

editCoverLetterButton.addEventListener('click', async () => {
  //Add this function call to an event listener or wherever you need to trigger the document creation
  // const coverLetterText = coverletterModalEditor.getText();
  var coverLetterContent = document.getElementById('coverLetterModelEditText').textContent; //.innerText
  var coverLetterText = document.getElementById('coverLetterModelEditText').innerText; //.innerText
  // console.log("innerText:");
  // console.log(coverLetterText);
  // console.log("content:");
  // console.log(coverLetterContent);
 
  //Replace four newlines with two
  coverLetterText = coverLetterText.replace(/\n\n\n\n\n/g, '\n\n\n');

  // Replace two newlines with one
  coverLetterText = coverLetterText.replace(/\n\n/g, '\n');
  // Replace each group of newline characters with one fewer newline
  // coverLetterText = coverLetterText.replace(/(\n+)/g, (match) => {
  //   return '\n'.repeat(Math.max(match.length - 1, 0));
  // });
  // console.log(coverLetterText);
  // console.log("cover letter text from quill modal edit:");
  // console.log(coverLetterText);
  const docgenspinner2 = document.getElementById('docgenspinner2');
  docgenspinner2.style.display = "block"; // Show
  // console.log("Displaying spinner?");
  try {
    await sendCoverLetterEditToLambda(coverLetterText);
} catch (error) {
    console.error('Error in sendCoverLetterEditToLambda:', error);
    showNotification("Error in send edited content to raven: " + err, 'error');
    
} finally {
    docgenspinner2.style.display = "none"; // Hide spinner after async operation
}

});

// webSocket.addEventListener('open', (event) => {
//   if (userId) {  // Check if the user is logged in
//     console.log('WebSocket connected successfully.');
//     console.log(event);
//     // showNotification("Connected to RavenAI", 'success');
//   } else {
//     console.log('WebSocket connection established, but no user is logged in.');
//   }
// });

// let messageChunks = '';

// webSocket.addEventListener('message', (event) => {
//   console.log("Response Payload:");
//   console.log(event.data);
//   // showSpinners();
//   const data = JSON.parse(event.data);

//   switch(data.type) {
    
//     case 'responseChunk':
//       console.log("Raven Response Chunk");
//       // Accumulate chunks of the message
//       messageChunks += data.message;
//       // Display the accumulating message chunks in real-time
//       candidateprofilesummary.style.display = 'block';
//       document.getElementById('candidateProfileMessage').innerHTML = messageChunks.replace(/\n/g, '<br>');

      
//       break;

//     case 'textExtracted':
//       console.log("textExtract completed")
//       console.log(data.type)

      
      
//       break;

//     case 'generationComplete':
//       if (typeof data.message === 'string') {
//           // const coverLetterTextarea = document.getElementById('finalCoverLetter');
//           // coverLetterTextarea.setAttribute('rows', 10);
//           // document.getElementById('finalCoverLetter').value = data.message;
//           finalCoverLetter.setText(data.message);
//           // finalCoverLetter.insertText(data.message);
//           // hideSpinners()
//           coverletterspinner.style.display = "none";
//           coverlettertick.style.display = "block";
          
//       } else {
//           console.error("Cover letter text is undefined or not present in the response.");
//       }
      
//       break;
    
//       case 'interviewPrepComplete':
//         if (data.message) {
//           const interviewPrepMessage = document.getElementById('interviewPrepMessage');
//           interviewPrepMessage.innerHTML = data.message.replace(/\n/g, '<br>'); // Replace newline characters with HTML line breaks
//           // hideSpinners();
//           interviewprepspinner.style.display = "none";
//           interviewpreptick.style.display = "block";
//         } else {
//           console.error("Job strategy text is undefined or not present in the response.");
//         }
//         break;

//         case 'candidateProfileAssessmentUpdate':
//           console.log("Candidate profile and job description extraction initiated.");
//           console.log(data.message);
//           break;
      
//         case 'candProfileComplete':
//           if (data.message) {
//             console.log("Candidate Profile Summary Payload Message");
//             console.log(data);
//             const candidateProfileMessage = document.getElementById('candidateProfileMessage');
//             candidateProfileMessage.innerHTML = data.message.replace(/\n/g, '<br>'); // Replace newline characters with HTML line breaks
//           } else {
//             console.error("Job strategy text is undefined or not present in the response.");
//           }
//           break;

//           case 'candProfileCompleted':
//           if (data.type === "candProfileCompleted" && data.message.length > 0) {
//             var candidateInfo = data.message[0]; // Access the first item in the message array
//             console.log(candidateInfo);
//             // Convert job_fit_score from string to number
//             const jobFitScore = parseFloat(candidateInfo.job_fit_score);
//             // Select the label_score element
            
//             // Check if jobFitScore is equal to or above 7.5 and update class
//             if (jobFitScore >= 7.5) {
//               document.getElementById('badge_score').className = 'badge badge-qualified padding-35'; // Update to success class
//             } else {
//               // Optional: handle other cases or leave as default
//               document.getElementById('badge_score').className = 'badge padding-35'; // Reset to default or other class as needed
//             };
//             document.getElementById('candidate_name_text').innerHTML = candidateInfo.candidate_name;
//             document.getElementById('candidate_summary_text').innerHTML = candidateInfo.resume_career_summary.replace(/\n/g, '<br>');
//             document.getElementById('job_listing_summary_text').innerHTML = candidateInfo.job_description.replace(/\n/g, '<br>');
//             document.getElementById('job_title_text').innerHTML = candidateInfo.job_description_job_title;
//             document.getElementById('company_name_text').innerHTML = candidateInfo.job_description_company_name;
//             document.getElementById('resume_score_text').innerHTML = candidateInfo.job_fit_score;
//             document.getElementById('scoring_content').innerHTML = candidateInfo.scoring_content;
//             document.getElementById('years_experience_text').innerHTML = candidateInfo.job_description_required_years_experience;
//             document.getElementById('candidate_summary_card').style.display = "block";
//             document.getElementById('candidateprofilesummary').style.display = "block";
//             document.getElementById('candidate_profile_generation_usermessage').style.display = "none";
//             document.getElementById('spinner').style.display = "none";
//             candidateprofilespinner.style.display = "none";
//             candidateprofiletick.style.display = "block";

//           } else {
//             console.error("Data type mismatch or empty message array.");
//           }
//           break;


//           case 'ravenGoogleSearchGPT':
//           const container = document.getElementById('ravenGoogleSearchGPT_results');
//           container.innerHTML = ''; // Clear existing content

//           // Check if data.message is empty
//           if (data.message.length === 0) {
//               const noResultsElement = document.createElement('div');
//               noResultsElement.style.padding = "10px";
//               noResultsElement.innerHTML = `<div class="padding-10 bg-contrast-lower"><div class="card-body padding-20"><h5 class="semi-bold no-padding no-margin">No immediate contacts found for this role. Please try again with a different job description.</h5></div></div>`;
//               container.appendChild(noResultsElement);
//           } else {
//               console.log("Search Results:");
//               console.log(data.message);
              
//               data.message.forEach(item => {
//                   const element = document.createElement('div');
//                   element.style.paddingBottom = "10px";
//                   element.innerHTML = `
//                       <div class="padding-10 bg-contrast-lower"
//                       <div class="card-body padding-20">
//                       <h5 class="semi-bold no-padding no-margin">${item.contact_name}</h5>
//                       <p class="">${item.title}</p>
//                       <p class="no-margin no-padding"><a href="${item.link}" target="_blank">${item.link}</a></p>
//                       <p class="no-margin no-padding p-t-5 semi-bold">Summary: <span class="light"> ${item.snippet} </span></p>
//                       <p class="no-margin no-padding p-t-5 semi-bold">Networking Suggestions: <span class="light"> ${item.networking_suggestions} </span></p>
//                       <p class="no-margin no-padding p-t-5 semi-bold">Recommended Ice-Breaker: <span class="light"> ${item.recommended_question} </span></p>
//                       </div>
//                       </div>
//                   `;
//                   container.appendChild(element);
//               });
//           }
//           document.getElementById('networkingtick').style.display = "block";
//           document.getElementById('networkingspinner').style.display = "none";
//           break;

          
//     // Handle other message types if necessary
//   }

  
// });


downloadButton.addEventListener('click', () => {
  if (selectedFile.url) {
    // Extract the filename from the file URL
    const fileName = selectedFile.key.split('/').pop(); // Use key for filename

    // Create a temporary anchor element for initiating the download
    const link = document.createElement('a');
    link.href = selectedFile.url; // Use the URL for href
    link.download = fileName; // Use the extracted filename

    // Append the link to the body, trigger the click (download), and then remove the link
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // console.log(`Initiated download for: ${fileName}`);
    showNotification("File exported. ", 'success');
  } else {
    console.error("No file selected for download");
    showNotification("No file selected: " + err, 'error');
   
  }
});



deleteButton.addEventListener('click', async () => {
  if (selectedFile.key) {
    try {
      // Replace 'deleteFile' with your own function to delete the file from storage
      await deleteFile(selectedFile.key);
      // console.log("File deleted");
      // Optionally, refresh the list of files displayed to the user
      await loadUploadedFiles();
    } catch (error) {
      console.error("Error deleting file:", error);
      // Optionally, display an error message to the user
    }
  } else {
    console.error("No file selected for deletion");
    showNotification("No file selected for seletion " + err, 'error');
    // Optionally, display an error message to the user indicating that a file must be selected
  }
});

async function deleteFile(fileKey) {
  // console.log("Initiating file deletion process...");

  try {
      // console.log(`Attempting to delete file with key: ${fileKey}`);

      // The 'Storage.remove' method is used to delete the file from S3.
      const result = await Storage.remove(fileKey, { level: 'private' });

      // If the deletion is successful, log the result.
      // console.log('File successfully deleted from S3:', result);
      // showNotification("File deleted.", 'success');

      return result;
  } catch (error) {
      // If there's an error in the deletion process, log the error details.
      console.error('Error encountered during file deletion from S3:', error);
      showNotification("Error fetching authenticated user: " + error, 'error');

      // Re-throw the error to be handled by the caller function.
      throw error;
  } finally {
      // This block will run regardless of the outcome (success or error).
      // console.log("File deletion process completed.");
      showNotification("File deleted.", 'success');
  }
}


// webSocket.addEventListener('error', (event) => {
//   console.log('WebSocket Error:', event);
//   showNotification("Websocket error: " + error, 'error');

//   // showErrorAlert('An error occurred while communicating with the server.');
// });

// webSocket.addEventListener('close', (event) => {
//   console.log('WebSocket is closed now.');
//   // console.log(event)
//   // showNotification("Websocket is closed", 'error');

//   // showErrorAlert('The connection to the server was closed.');
// });




console.log("upload.js loaded...");

