/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://x43twucadrdm7kzes3yacjy7ji.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-rlkgbzgtwndahphollvbbsir54",
    "aws_cognito_identity_pool_id": "us-west-2:5746dd1f-e91f-4952-a5ec-751fc8c8d1e3",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_oZxCatQhm",
    "aws_user_pools_web_client_id": "53245jhsg20rjl9tt0enthba2j",
    "oauth": {},
    "federationTarget": "COGNITO_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_mobile_analytics_app_id": "5f80a8106d454b70954c7b058599eb16",
    "aws_mobile_analytics_app_region": "us-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "5f80a8106d454b70954c7b058599eb16",
            "region": "us-west-2"
        }
    },
    "aws_user_files_s3_bucket": "resumeravenpro25f5e9e07f2442b5af27212a5c85f0d5121432-dev",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
