
import { uploadDocument,fetchUserId, showNotification } from './upload';
import { userAuthState } from './auth_user';


export function checkAuthContent() {
    // Analytics.record({ name: 'authVisit' });

    if (document.querySelector("#authenticated-content")) {
        userAuthState()
            .then(data => {
                // Analytics.record({ name: 'authContentSuccess' });
                // console.log('user is authenticated: ', data);
            })
            .catch(error => {
                // Analytics.record({ name: 'authContentError' });
                // console.log('user is not authenticated: ', error);
                alert("You are not authenticated and will be redirected to login.");
                window.location = '/login.html';
            });
    } else {
        userAuthState()
            .then(data => {
                // console.log('user is authenticated: ', data);
                console.log('user is authenticated');
            })
            .catch(error => {
                // console.log('user is not authenticated: ', error);
                console.log('user is not authenticated.');
                showNotification("You are not authenticated.", 'warning');
            });
    }
}
