/* eslint-disable */
console.log("Initiating verification link loading....");

import { Auth } from 'aws-amplify';
import { showNotification } from './upload';

function showPreloaderConfirm() {
  $('.js-preloader').show(); // Assuming your preloader has a class 'js-preloader'
}

function hidePreloaderConfirm() {
  $('.js-preloader').fadeOut('slow', function() {
    $(this).remove();
  });
}

function confirm() {
  console.log("Confirming");
  // showPreloaderConfirm();
  var target = document.getElementById('myspinner');
  var spinner = new Spinner().spin(target);
  const urlParams = new URLSearchParams(window.location.search);
  const encoded = urlParams.get('data');
  const code = urlParams.get('code');
  console.log('code');
  console.log(code);

  // Check if 'data' and 'code' parameters are present
  if (!encoded || !code) {
    console.log("No verification data found in URL. Exiting confirm function.");
    return; // Exit the function if parameters are not found
  }

  // Decoding and Parsing with Error Handling
  let decoded;
  try {
    const decodedString = atob(decodeURIComponent(encoded));
    console.log('Decoded Base64:', decodedString);
    decoded = JSON.parse(decodedString);
  } catch (e) {
    console.error('Error decoding and parsing data:', e);
    return;
  }

  const { userName, redirectUrl, clientId } = decoded;

  // // AWS Cognito Confirmation and Redirection using Amplify
  // Auth.confirmSignUp(userName, code)
  //   .then(() => window.location.replace(redirectUrl))
  //   .catch(err => {
  //     console.error('Error confirming sign up:', err);
      
  //   //   window.location.replace('/login.html'); // Replace with your error page URL
  //   });
  Auth.confirmSignUp(userName, code)
    .then(() => {
      console.log('Confirming username and code');
      
      // Hide preloader when confirmation is successful
      // hidePreloaderConfirm();
      window.location.replace('/main.html');
    })
    .catch(err => {
      console.error('Error confirming sign up:', err);
      // Hide preloader if there's an error
      // hidePreloaderConfirm();
      // window.location.replace('/login.html'); // Replace with your error page URL
    });
}

// window.onload = function () {
//   console.log("Initiating confirmation function link verification....");
//   confirm();
// };

document.addEventListener('DOMContentLoaded', (event) => {
    // showPreloaderConfirm();
    console.log("DOM fully loaded and parsed");
    console.log(event);
    confirm();
  });
console.log("Verification link loading completed.");
