// console.log("auth_login.js loaded...");
import $ from 'jquery';
import { Auth } from 'aws-amplify';
import { uploadDocument,fetchUserId, showNotification } from './upload';

// console.log(Auth);

export function showNotificationAlert(message, type, style = 'circle', position = 'top-right') {
    $('.page-content-wrapper ').pgNotification({
        title: "Notification",
        style: style,
        message: message,
        position: position,
        type: type,
        timeout: 4000, // Adjust timeout as needed
        thumbnail: '<img width="40" height="40" style="display: inline-block;" src="assets/img/cloudraven-logo-only.svg" data-src="assets/img/cloudraven-logo-only.svg" data-src-retina="assets/img/cloudraven-logo-only.svg" alt="">'
    }).show();
  }

// Sign In function
export const signIn = async ({username, password}) => {
    try {
        // console.log(username);
        // console.log(password);
        const { user } = await Auth.signIn(username, password);
        // console.log(user)
        Auth.currentSession().then(res=>{
            let accessToken = res.getAccessToken()
            let jwt = accessToken.getJwtToken()
        })
        window.location = '/main.html'
    } catch (error) {
        console.log('error signing in', error);
        console.log(error.message);
        console.log(error.code)
        
        // alert(errorMessage)
        showNotification("Error signing in: " + error.message, 'error');
        
        setTimeout(() => {
            window.location = '/login.html';
        }, 30000); // Redirect after 3 seconds
    };
    
    
};

// Event Listeners if user is on Login page
if (document.querySelector("#auth-login")) {

    document.querySelector("#form-auth-login").addEventListener("click", event => {
        event.preventDefault();
    });

    document.querySelector("#btnLogin").addEventListener("click", () => {
        const username = document.querySelector("#formLoginEmail").value
        const password = document.querySelector("#formLoginPassword").value
        signIn({username, password});
        // Analytics.record({ name: 'appLogin' }); // Moved inside the click event listener
    });
};
